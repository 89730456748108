var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      directives: [
        {
          name: "ripple",
          rawName: "v-ripple",
          value: _vm.$utilities.getRippleOptions("light"),
          expression: "$utilities.getRippleOptions('light')",
        },
      ],
      class: _vm.classes,
      attrs: { disabled: _vm.isDisabled },
      on: {
        click: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      !_vm.isLoading
        ? [
            _c("Icon", {
              attrs: {
                name: _vm.iconName,
                color: _vm.iconColor,
                width: _vm.size,
                height: _vm.size,
              },
            }),
          ]
        : [_c("DpSpinner", { attrs: { size: "1.1em" } })],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <button
    :class="classes"
    :disabled="isDisabled"
    v-ripple="$utilities.getRippleOptions('light')"
    @click.prevent.stop="onClick">
    <template v-if="!isLoading">
      <Icon :name="iconName" :color="iconColor" :width="size" :height="size" />
    </template>
    <template v-else>
      <DpSpinner size="1.1em" />
    </template>
  </button>
</template>

<script>
import { SystemDialogMessage } from '@/constants/enums';
import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    DpSpinner: () => import('@/components/dripPod/core/Spinner.vue')
  },

  props: {
    id: {
      type: [Number, undefined],
      default: undefined
    },

    size: {
      type: Number,
      default: 20
    },

    paddingSize: {
      type: String,
      default: ''
    },

    state: {
      type: Boolean,
      default: false
    }
  },

  setup(props, context) {
    const isLoading = ref(false);
    const isDisabled = ref(false);
    const stateValue = ref(false);

    const isActive = computed(() => stateValue.value);

    const sleep = (ms = 100) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const classes = computed(() => {
      return [
        'dp-myrecipe-button',
        props.paddingSize === 'sm' ? 'dp-myrecipe-button--padding-sm' : false
      ];
    });

    const iconName = computed(() =>
      isActive.value ? 'dpBookmarkOnR' : 'dpBookmarkOffR'
    );

    const iconColor = computed(() =>
      isActive.value ? 'dpBlack01' : 'dpGrey88'
    );

    const onClick = () => {
      // ボタンを押せる状態の場合にのみ切り替え
      if (!isDisabled.value && !isLoading.value) switchButton();
    };

    const postState = async (value) => {
      if (!value) return;
      const params = {
        is_bookmark: value
      };
      try {
        await context.root
          .$repositories('dpBrewMyrecipe')
          .setMyrecipeStatus(props.id, params)
          .then(async (response) => {
            const { data } = await response;
            stateValue.value = data.bookmark;
            context.emit('switch-myrecipe-button', stateValue.value);
          });
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    // ブックマークが成功したらダイアログを表示
    const showSavedDialog = () => {
      if (stateValue.value) {
        window.location.href =
          context.root.$customUrlScheme.dialog('bookmark-saved');
      }
    };

    const switchButton = async () => {
      isDisabled.value = true;
      isLoading.value = true;

      /**
       * 未ブックマークであればブックマーク登録
       * ブックマーク済みあれば解除確認のダイアログを表示
       */
      if (!stateValue.value) {
        await postState(!stateValue.value);

        // 表示的な都合
        await sleep(100);

        isLoading.value = false;
        isDisabled.value = false;

        showSavedDialog();
      } else {
        window.location.href = context.root.$customUrlScheme.dialog(
          `bookmark-delete/?bookmark_id=${props.id}`
        );
        isLoading.value = false;
        isDisabled.value = false;
      }
    };

    stateValue.value = props.state;

    return {
      isLoading,
      isActive,
      isDisabled,
      stateValue,
      classes,
      iconName,
      iconColor,
      onClick
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-myrecipe-button {
  display: grid;
  place-items: center;
  padding: 16px;
  width: 52px;
  height: 52px;

  &--padding-sm {
    padding: 8px 4px;
    width: 36px;
    height: 44px;
  }
}
</style>
